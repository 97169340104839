<template>
  <div class="col-md-12">
    <div class="modal fade modal_cust show" v-if="modalId=='planDetails'" :class="modalState?'show':''" tabindex="-1" role="dialog" style="display: block;" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal_ac_head" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              {{details.title}} Plan
              <small>( Rs : {{details.price}} )</small>
            </h5>
            <a class="close"  @click="$store.dispatch('modalClose')">
              <span aria-hidden="true">×</span>
            </a>
          </div>
          <form @submit.prevent="editPlan" method="post" class="bg-theam-secondary" enctype="multipart/form-data">
            <div class="modal-body row">
              <div class="col-md-6 pd-r-7-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group">
                      <label class="group-label">Plan Detail</label>
                      <div class="group-attribute-container">
                        <div class="row">
                          <div class="col-md-6 mr-0 pr-0">
                            <label for="title">Title</label>
                          </div>
                          <div class="col-md-6" :class="{ invalid: $v.formData.title.$error }">
                            <input
                                    type="text"
                                    class="form-control border-radius-0"
                                    placeholder="Title"
                                    id="title"
                                    @blur="$v.formData.title.$touch()"
                                    v-model.trim="formData.title"
                                    required
                            />
                          </div>
                          <div class="col-md-12 text-right" v-if="$v.formData.title.$error">
                            <p
                                    class="error_level"
                                    v-if="!$v.formData.title.required"
                            >Title field is required.</p>
                            <p
                                    class="error_level"
                                    v-if="!$v.formData.title.minLength"
                            >At least 2 character title.</p>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-6 mr-0 pr-0">
                            <label for="branches">No. Of. Branches</label>
                          </div>
                          <div class="col-md-6" :class="{ invalid: $v.formData.branches.$error }">
                            <input
                                    type="number"
                                    class="form-control border-radius-0"
                                    placeholder="Branches"
                                    id="branches"
                                    @blur="$v.formData.branches.$touch()"
                                    v-model.trim.number="formData.branches"
                                    required
                            />
                          </div>
                          <div class="col-md-12 text-right" v-if="$v.formData.branches.$error">
                            <p
                                    class="error_level"
                                    v-if="!$v.formData.branches.required"
                            >Branch field is required.</p>
                            <p
                                    class="error_level"
                                    v-if="!$v.formData.branches.numeric"
                            >Please enter numeric value.</p>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-6 mr-0 pr-0">
                            <label for="users">No. Of. Users</label>
                          </div>
                          <div class="col-md-6" :class="{ invalid: $v.formData.users.$error }">
                            <input
                                    type="number"
                                    class="form-control border-radius-0"
                                    placeholder="Users"
                                    id="users"
                                    @blur="$v.formData.users.$touch()"
                                    v-model.trim.number="formData.users"
                                    required
                            />
                          </div>
                          <div class="col-md-12 text-right" v-if="$v.formData.users.$error">
                            <p
                                    class="error_level"
                                    v-if="!$v.formData.users.required"
                            >User field is required.</p>
                            <p
                                    class="error_level"
                                    v-if="!$v.formData.users.numeric"
                            >Please enter numeric value.</p>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-6 mr-0 pr-0">
                            <label for="users">Price</label>
                          </div>
                          <div class="col-md-6" :class="{ invalid: $v.formData.price.$error }">
                            <input
                                    type="number"
                                    step="any"
                                    class="form-control border-radius-0"
                                    placeholder="Price"
                                    id="price"
                                    @blur="$v.formData.price.$touch()"
                                    v-model.trim.number="formData.price"
                                    required
                            />
                          </div>
                          <div class="col-md-12 text-right" v-if="$v.formData.price.$error">
                            <p
                                    class="error_level"
                                    v-if="!$v.formData.price.required"
                            >User field is required.</p>
                            <p
                                    class="error_level"
                                    v-if="!$v.formData.price.numeric"
                            >Please enter numeric value.</p>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-6 mr-0 pr-0">
                            <label>Featured</label>
                          </div>
                          <div class="col-md-6 d-flex">
                            <input
                                    type="radio"
                                    class="form-control border-radius-0"
                                    id="yes"
                                    v-model.trim.number="formData.featured"
                                    value="1"
                            />
                            <label for="yes" class="mt-2 ml-2">YES</label>
                            <input
                                    type="radio"
                                    class="form-control border-radius-0 ml-5"
                                    id="no"
                                    v-model.trim.number="formData.featured"
                                    value="0"
                            />
                            <label for="no" class="mt-2 ml-2">NO</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-right mt-3">
                <button type="submit" class="btn btn-sm btn-primary">Submit</button>
                <p :class="eventMessage.indexOf('success')>=0?'success_level':'error_level'"
                >{{eventMessage}}</p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  /* disable eslint */
  import { mapGetters } from "vuex";
  import {
    maxLength,
    minLength,
    numeric,
    required,
  } from "vuelidate/lib/validators";
  export default {
    data() {
      return {
        details: [],
        formData: {
          title: "",
          branches: "",
          users: "",
          price: "",
          featured: 0,
        },
      };
    },
    validations: {
      formData: {
        title: { required, minLength: minLength(2), maxLength: maxLength(255) },
        branches: { required, numeric },
        users: { required, numeric },
        price: { required, numeric },
      },
    },
    methods: {
      editPlan() {
        this.$store.commit("setApiUrl", `api/plan/${this.details.id}`);
        this.$store.commit("updateData", this.formData);
      },
    },
    computed: {
      ...mapGetters([
        "processing",
        "eventMessage",
        "dataLists",
        "dataId",
        "modalId",
        "modalState",
      ]),
    },
    watch: {
      dataId(value) {
        this.details = this.dataLists.find(function (data) {
          return data.id == value;
        });
        this.formData.title = this.details.title;
        this.formData.branches = this.details.branches;
        this.formData.users = this.details.users;
        this.formData.price = this.details.price;
        this.formData.featured = this.details.featured;
      },
    },
  };
</script>