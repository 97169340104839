<template>
  <div class="content-body">
    <div class="row mg-0">
      <div class="col-sm-5">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">Product Setup</a></li>
                <li class="breadcrumb-item text-capitalize active" aria-current="page">Plans</li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Manage plans</h4>
          </div>
        </div>
      </div>
      <div class="col-sm-0 tx-right col-lg-7">
        <button type="button" class="btn btn-primary mg-t-8 mg-r-0 mg-b-0" @click="$store.dispatch('modalOpen','createPlan')">New Plan</button>
      </div><!-- col -->
    </div><!-- row -->
    <div class="bg-theam-secondary table-search-head">
      <div class="row">
        <div class="filter-input col-md-9 d-flex mr-0 pr-0">
          <select v-model="filter.size">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
          </select>
        </div>
        <div class="col-md-3 ml-0 pl-0">
          <input type="text" v-model="filter.key"  class="float-right" placeholder="Search...">
        </div>
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12">
        <table class="table table2 table-hover" id="tableData">
          <thead>
          <tr>
            <th scope="col" class="text-center">S.N</th>
            <th scope="col">Plan</th>
            <th scope="col">No. of Branch</th>
            <th scope="col">No. of User</th>
            <th scope="col">Price</th>
            <th scope="col" class="text-center">Action</th>
          </tr>
          </thead>
          <tbody v-for="(plan,index) in dataSets" :key="index">
          <tr v-if="!removeList.includes(plan.id)">
            <th scope="row" class="text-center">{{++index}}</th>
            <td>{{plan.title}}</td>
            <td>{{plan.branches}}</td>
            <td>{{plan.users}}</td>
            <td>{{plan.price}}</td>
            <td class="text-center">
              <a
                      href="javascript:;"
                      @click="edit('planDetails',plan.id)"
                      title="More about plan details" class="mr-3"
              >
                <i class="far fa-edit"></i>
              </a>
              <a href="javascript:;" @click="drop(plan.id)" title="Drop Plan">
                <i class="fa fa-trash"></i>
              </a>
            </td>
          </tr>
          </tbody>
        </table>
        <Pagination />
      </div>
    </div>
    <Create />
    <Edit />
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import Edit from "./edit";
  import Create from "./create";
  import Pagination from "../components/pagination/pagination";
  import {Search} from "../../../mixins/search";

  export default {
    mixins: [Search],
    components: { Create, Edit, Pagination },
    data() {
      return {
        state: "",
      };
    },
    methods: {
      edit(modal, id) {
        this.$store.commit("setDataId", id);
        this.$store.dispatch("modalOpen", modal);
      },
      drop(id) {
        this.$store.commit("setDataId", id);
        this.$store.commit("dropRecord", `api/plan/${id}`);
      },
      getData(){
        this.$store.commit("getData", `api/plans/size/${this.filter.size}`);
      }
    },
    computed: {
      ...mapGetters([
        "icons",
        "today",
        "dataId",
        "dataLists",
        "removeList",
        "pagination",
        "eventMessage",
      ]),
    },
    mounted() {
      this.getData();
    },
    watch: {
      eventMessage(value) {
        if (value.indexOf("deleted success") >= 0) {
          this.$store.commit("setRemoveList", this.dataId);
        }
      },
    },destroyed() {
      this.$store.dispatch("destroyEvent");
    }
  };
</script>